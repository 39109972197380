import React from 'react'

import Layout from '../../components/layout';

import AsyncDownload from '../../components/async-download';

import {graphql} from 'gatsby';

import DownloadWinChrome from "../../img/SVG/download-chrome-win.svg";
import DownloadWinEdge from "../../img/SVG/download-edge-win.svg";
import DownloadWinFirefox from "../../img/SVG/download-firefox-win.svg";
import NonStretchedImage from '../../components/non-stretchd-image';

const {detect} = require('detect-browser');


function justFileName(url)
{
    return url.match(/([^/]*)$/)[0];
}

class DownloadPageWindows extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            arch: "32",
            archOther: "64",
            downloadUrl: this.props.data.site.siteMetadata.downloadWin32,
            downloadUrlOther: this.props.data.site.siteMetadata.downloadWin64,
            fileName: justFileName(this.props.data.site.siteMetadata.downloadWin32),
            browser: "edge"
        };
    }

    componentDidMount()
    {
        const platform = navigator.platform;
        const agent = navigator.userAgent;
        const browser = detect();

        const o = /x64[;)]|x86-64|x86_64|x64-64|x64_64|Win64|WOW64|amd64|ia64/i;
        if (o.test(platform) || o.test(agent))
        {
            this.setState({
                arch: "64",
                archOther: "32",
                downloadUrl: this.props.data.site.siteMetadata.downloadWin64,
                downloadUrlOther: this.props.data.site.siteMetadata.downloadWin32,
                fileName: justFileName(this.props.data.site.siteMetadata.downloadWin64),
                browser: browser.name
            });
        }
    }

    render()
    {
        let text;

        if (this.state.browser === "chrome")
        {
            text = <>
                <p>
                    Bitte warte, bis das Herunterladen abgeschlossen ist, dann klicke
                    auf die Schaltfäche mit der Aufschrift <b>{this.state.fileName}</b> unten links in der Ecke
                    dieses Fensters.
                </p>
                <DownloadWinChrome style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                     Klicke anschließend auf <b>Ok</b> um mit der Installation zu beginnen.
                </p>
            </>

        }
        else if (this.state.browser === "firefox")
        {
            text = <>
                <p>
                    FireFox fragt Dich, was Du mit der Datei <b>{this.state.fileName}</b> machen willst.
                    Wähle <b>Öffnen mit: DiskImageMounter (default)</b> und klicke auf <b>Ok</b>.
                    FireFox sollte dann automatisch das <a href="#installing">Installations-Fenster</a> anzeigen.
                </p>
                <p>
                    Falls das nicht klappt, klicke auf den kleinen Pfeil oben rechts in der Ecke:
                </p>
                <DownloadWinFirefox style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                    Warte, bis das Herunterladen abgeschlossen ist und
                    klicke dann auf <b>{this.state.fileName}</b>
                    um mit der Installation zu beginnen.
                </p>
            </>
        }
        else /* Edge / IE */
        {
            text = <>
                <p>
                    Dein Browser fragt Dich, was Du mit der Datei <b>{this.state.fileName}</b> machen
                    willst.
                    Klicke auf <b>Ausführen</b> um die Installation nach dem Herunterladen
                    zu starten.
                </p>
                <DownloadWinEdge style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                    Microsoft wird noch einen Sicherheits-Check durchführen um zu
                    überprüfen, dass die Datei wirklich keine bösartige Software
                    enthält. Die Installation startet direkt nachdem die Überprüfung
                    abgeschlossen ist.
                </p>
            </>
        }

        return (
            <Layout lang={'de'}
                    className={'nav--no_border'}
                    title={'2stitch Organizer - Stickdateien auf Windows PCs anzeigen'}
                    description={'Gratis Programm. Stick-Dateien anschauen, sortieren und finden. Unterstützt Bernina, Brother (PES), Janome (SEW), Pfaff (PCS), Husquarna (HUS, VIP)'}
                    translated={{en:'/download-organizer-windows/'}}
                    location={this.props.location.pathname}
            >


                <div className={'bg-blue-50 text-blue-900 pb-12 pt-12 border-t border-b border-blue-900'}>
                    <div className="container mx-auto">

                        <AsyncDownload
                            url={this.state.downloadUrl}
                            productName={'2stitch Organizer'}
                        />
                        <div className="text-center">
                            <small className="text-center inline-block mt-4">
                                Du lädst gerade die Version für  {this.state.arch}bit Windows herunter.<br/>
                                Klicke hier, falls Du die <a href={this.state.downloadUrlOther}>{this.state.archOther}bit
                                Version</a> verwenden möchtest.
                            </small>
                        </div>
                    </div>
                </div>

                <div className={'container mx-auto mt-12 markdown'}>
                    <h2 className={'mb-8 text-3xl'}>2stitch Organizer auf deinem Windows PC herunter laden</h2>
                    {text}
                    <p>
                        Wenn alls gut gegangen ist, solltest Du nun ein Fenster mit der Installation
                        von 2stitch Organizer sehen.
                        Falls dies nicht der Fall ist öffne den <b>Explorer</b> und suche den Ordner mit dem
                        Namen <b>Downloads</b>.
                        Doppel-Klicke auf <b>{justFileName(this.state.downloadUrl)}</b> um die Installation zu starten.
                    </p>


                    <h2>Installation von 2stitch Organizer</h2>

                    <p>Du solltest nun das folgende Fenster sehen. Klicke auf <b>Weiter</b>:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install1.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>
                        Bitte lies Dir die Lizenz-Vereinbarung durch, wenn Du einverstanden bist
                        klicke auf das kleine Kästchen neben <b>Ich akzeptiere die Lizenzvereinbarung</b>.
                        Klicke anschließend auf <b>Weiter</b>
                    </p>

                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install2.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Noch einmal auf <b>Weiter</b> klicken:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install3.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>...und jetzt auf : <b>Installieren</b>:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install4.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Klicke auf <b>Ja</b> um die Installation zu starten:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install5.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>Nun noch auf <b>Beenden</b> um die Installation abzuschließen:</p>
                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.install6.childImageSharp.fluid}/>
                    </div>

                    <p className={'pt-8'}>
                        Wenn Du nun alle Fenster schließt solltest Du das <b>2stitch Organzier</b> Icon
                         auf dem Desktop sehen.
                    </p>
                </div>

            </Layout>
        )
            ;
    }
}

export default DownloadPageWindows;




export const query = graphql`
    query DownloadPageWindowsQueryDe {
        site {
            siteMetadata {
                downloadWin32
                downloadWin64
            }
        }

        install1: file(relativePath: { eq: "organizer/install-win-1.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }

        install2: file(relativePath: { eq: "organizer/install-win-2.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }

        install3: file(relativePath: { eq: "organizer/install-win-3.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }

        install4: file(relativePath: { eq: "organizer/install-win-4.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                }
            }
        }

        install5: file(relativePath: { eq: "organizer/install-win-5.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                }
            }
        }

        install6: file(relativePath: { eq: "organizer/install-win-6.png" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth:593) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
            }
        }
    }
`;
